import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import Grid from '../components/Grid';
import CoverContact from '../components/covers/CoverContact';
import TransformNav from '../components/TransformNav';
import PageTransition from '../components/PageTransition';
import SEO from '../components/Seo';

export default function contact({ data }) {
  const blocks = data.page._rawBlocks;

  const FieldsetStyles = styled.fieldset`
    display: grid;
    max-width: var(--grid-width);
    margin: 0 auto;
    padding: 0 px 0px;
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--beige);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    label {
      @media screen and (max-width: 39.9375em) {
        grid-column: 1 / span 4;
      }
      grid-column: 1 / span 3;
      margin-bottom: 0px;
      display: flex;
      padding-top: 20px;
      align-items: flex-start;
      color: var(--red);
      padding-bottom: 13px;
    }
    input {
      border-bottom: 0;
    }
    input,
    textarea {
      color: var(--red);
      opacity: 0.8;
      grid-column: span 8;
      margin-bottom: 0px;
      outline: none;
      padding: 0;
      border: 0;
      background: rgba(255, 255, 255, 0);
    }
  `;

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const formElement = useRef(null);
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });
  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    setError(null);
    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const text = JSON.parse(await res.text());
    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setMessage('Bericht is succesvol verzonden!');
    }
  };

  return (
    <>
      <SEO title={data.page.seo_title} desc={data.page.seo_description} />
      <PageTransition>
        <TransformNav />

        {blocks?.map((block, i) => (
          <React.Fragment key={i}>
            {block._type === 'block' ? null : null}
            {block._type === 'blockContact' ? (
              <CoverContact
                place={block.place}
                street={block.street}
                zipcode={block.zipcode}
                vimeoUrl={block.vimeo_url}
                facebookUrl={block.facebook_url}
                instagramUrl={block.instagram_url}
                mail={block.mail}
                phone={block.phone}
              />
            ) : null}
          </React.Fragment>
        ))}

        <Grid>
          <div className="container">
            <h1>Contact</h1>
            <p>
              <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
                <FieldsetStyles disabled={loading}>
                  <label htmlFor="voornaam">Voornaam</label>
                  <input
                    type="text"
                    placeholder="Voornaam"
                    {...register('voornaam', {
                      required: 'Vul uw voornaam in.',
                    })}
                  />
                </FieldsetStyles>
                <FieldsetStyles disabled={loading}>
                  <label htmlFor="achternaam">Achternaam</label>
                  <input
                    type="text"
                    placeholder="Achternaam"
                    {...register('achternaam', {
                      required: 'Vul uw achternaam in.',
                    })}
                  />
                </FieldsetStyles>
                <FieldsetStyles disabled={loading}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    {...register('email', {
                      required: 'Vul uw email in.',
                    })}
                  />
                </FieldsetStyles>
                <FieldsetStyles disabled={loading}>
                  <label htmlFor="Bericht" style={{ paddingTop: '0' }}>
                    Bericht
                  </label>
                  <textarea
                    name="bericht"
                    placeholder="Bericht"
                    {...register('bericht', {
                      required: 'Vul uw bericht in.',
                    })}
                    id="Bericht"
                    cols="30"
                    rows="10"
                    placeholder="Bericht"
                  />
                </FieldsetStyles>
                {message ? <p>{message}</p> : null}
                <button type="submit" className="button" disabled={loading}>
                  Verstuur tipformulier
                </button>
              </form>
            </p>
          </div>
        </Grid>
      </PageTransition>
    </>
  );
}

export const query = graphql`
  query Contact {
    page: sanityPage(slug: { current: { eq: "contact" } }) {
      name
      seo_title
      seo_description
      _rawBlocks(resolveReferences: { maxDepth: 10 })
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;
